<template>
  <div class="box-nav">
    <van-nav-bar title="个人设置" left-text="" right-text="" left-arrow @click-left="$router.go(-1)" />
    <van-cell-group>
      <van-cell title="账号名称" :value="peoGeninfos.Uuser" />
      <van-cell title="学校名称" :value="peoGeninfos.CC_Name" />
      <!-- <van-cell title="姓名" :value="peoGeninfos.Uname" /> -->
      <van-cell title="修改密码" is-link to="/M_Password2" />
    </van-cell-group>
  </div>
</template>

<script>
import { getmyuser2 } from "@/api/J_Sign";
export default {
  name: "setupcenter",
  data() {
    return {
      peoGeninfos: {}
    }
  },
  mounted() {
    this.getpeoInfos()
  },
  methods: {
    getpeoInfos() {
      getmyuser2().then((res) => {
        console.log('数据测试', res)
        this.peoGeninfos = res.data.mydata;
      });
    },
  },
};
</script>

<style scoped lang="less">
.box-nav {
  width: 100%;
}
/deep/ .van-nav-bar .van-icon {
  color: #333;
}
</style>